
const submit = () => {
    return new Promise(resolve => {
        var settings = {
            "url": "./api/forgotpassword",
            "method": "POST",
            "headers": {
                "Content-Type": "application/json"
            },
            "data": JSON.stringify({
                "email_text" : $('#email-text').val()
            })
        };
        $.ajax(settings)
            .always(function (url) {
                resolve(url);
            });
    });
};

const _ShowPassword = (_event, _this) => {
    $(_this).toggleClass("fa-eye fa-eye-slash");
    let input = $($(_this).attr("toggle"));
    if (input.attr("type") == "password") {
        input.attr("type", "text");
    } else {
        input.attr("type", "password");
    }
}

window._ShowPassword = _ShowPassword;

$(() => {
            
    $('#fp_submit_butt').off().on('click', function(){  
        $(this).prop('disabled', true);
        submit().then((data) => {
            $(this).prop('disabled', false);

            if(data.response.success){
                $('#forgotPassModal').modal('hide')
                _notif('success', data.response.outMessage)
                return false;
            }
            
            _notif('warning', data.response.outMessage)
        })
    }); 

    $('#loginForm').submit(function(){
        $(this).find(':input[type=submit]').prop('disabled', true);
    });
    
});